import request from '../utils/request.js'

//首页轮播图
export function banner (query) {
  return request({
    url: '/website/index/hotClassifyBrandTopList',
    method: 'get',
    params: query
  })
}

//热门排行（行业）
export function hotPaihang (query) {
  return request({
    url: '/website/index/hotClassifyList',
    method: 'get',
    params: query
  })
}

//热门品牌
export function hotPinpai (query) {
  return request({
    url: '/website/index/hotBrandList',
    method: 'get',
    params: query
  })
}

//城市排行
export function cityPaihang (query) {
  return request({
    url: '/website/index/hotCityList',
    method: 'get',
    params: query
  })
}


//品质生活
export function shenghuo (query) {
  return request({
    url: '/website/index/clothingList',
    method: 'get',
    params: query
  })
}

//今日TOP榜
export function todayTop (query) {
  return request({
    url: '/website/index/topArticleList',
    method: 'get',
    params: query
  })
}

//行业十大品牌榜
export function shida (query) {
  return request({
    url: '/website/index/hotClassifyTitleList',
    method: 'get',
    params: query
  })
}



//品牌推荐
export function tuijian (query) {
  return request({
    url: '/website/index/hotBrandWorldList',
    method: 'get',
    params: query
  })
}

//旅游娱乐
export function lvyou (query) {
  return request({
    url: '/website/index/tourismList',
    method: 'get',
    params: query
  })
}

//国家地区馆
export function guojia (query) {
  return request({
    url: '/website/index/countryList',
    method: 'get',
    params: query
  })
}





