<template>
	<div class="container">
		<Header ref="headerBar" @onSearch="searchBtn"></Header>
		<div class="search-conWrap" v-if="searchList.length>0">
			<div class="breadcrumbs wrap">
				<img src="../../assets/images/icon-home.png" alt="" class="breadcrumbs-icon" />
				<a href="/">首页</a> > 十大{{type}}CNPP > {{keywords}}
			</div>
			<div class="i-main wrap clearfix">
				<div class="searcl-left fl">
					<div class="searcl-l1 clearfix">
						<ul class="searcl-l1-list" v-if="type=='行业'">
							<li v-for="(item,a) in searchList" :key="a" @click="listBtn(item)">
								<a href="javascript:void(0)">
									<div class="searcl-l1-logo">
										<img :src="imgUrl+item.industryTrans" alt="" />
									</div>
									<div class="searcl-l1-info">
										<p><span>{{item.industryName}}</span>{{item.parentName}}</p>
									</div>
									<img src="../../assets/images/arrow-r-yuan.png" alt="" class="searcl-l1-arrow" />
								</a>
							</li>
						</ul>
						<ul class="searcl-l1-list" v-else>
							<li v-for="(item,a) in searchList" :key="a" @click="listBtn(item)">
								<a href="javascript:void(0)">
									<div class="searcl-l1-logo">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</div>
									<div class="searcl-l1-info">
										<p><span>{{item.brandName}}</span>{{item.enterName}}</p>
									</div>
									<img src="../../assets/images/arrow-r-yuan.png" alt="" class="searcl-l1-arrow" />
								</a>
							</li>
						</ul>
					</div>

					<div class="searcl-l2">
						<div class="rank-left-hot-title">
							<h2><span>{{type}}上新</span></h2>
						</div>
						<ul class="rank-left-hot-list" v-if="type=='行业'">
							<li v-for="(item,b) in newList" :key="b" @click="listBtn(item)">
								<a href="javascript:void(0)">
									<div class="rank-left-hot-list-img">
										<img :src="imgUrl+item.industryTrans" alt="" />
									</div>
									<p>{{item.industryName}}</p>
								</a>
							</li>
						</ul>
						<ul class="rank-left-hot-list" v-else>
							<li v-for="(item,b) in newList" :key="b" @click="listBtn(item)">
								<a href="javascript:void(0)">
									<div class="rank-left-hot-list-img">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</div>
									<p>{{item.brandName}}</p>
								</a>
							</li>
						</ul>

					</div><!-- searcl-l2 end -->
				</div><!-- searcl-left end -->

				<div class="searcl-right fr">
					<div class="rank-r1 rank-right-box border-10">
						<div class="rank-right-title clearfix">
							<h3><img src="../../assets/images/icon-zuanshi.png" alt="" />热门行业</h3>
						</div>
						<div class="rank-r1-list">
							<ul>
								<li v-for="(item,c) in hangyeList" :key="c" @click="hangyeBtn(item)">
									<a href="javascript:void(0)">
										<span class="rank-r1-list-img"><img :src="imgUrl+item.industryTrans"
												alt="" /></span>
										<p>{{item.industryName}}</p>
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div class="rank-r1 rank-right-box border-10 mt-20">

						<div class="rank-right-title clearfix">
							<h3><img src="../../assets/images/icon-huangguan.png" alt="" />热门品牌</h3>
						</div>

						<div class="search-r2-list">
							<ul>
								<li v-for="(item,d) in pinpaiList" :key="d"  @click="pinpaiBtn(item)">
									<a href="javascript:void(0)">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</a>
								</li>
							</ul>
						</div>
					</div>

				</div><!-- searcl-right end -->
			</div><!-- i-main end  -->

		</div><!-- search-conWrap end -->

		<!-- 404页面显示 -->
		<div class="search404-conWrap" v-else>
			<div class="search404-con wrap">
				<img src="../../assets/images/search404_03.png" alt="" class="search404-img" />
				<p>很抱歉，未搜索到相关内容</p>
				<el-button type="danger" class="backHome-btn mt-30" @click="backHome">返回首页</el-button>
			</div>

		</div><!-- search-conWrap end -->


		<div class="i-m9-wrap">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->

		<Footer></Footer>

		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>


	</div>
</template>

<script>
	import {
		hotPaihang,
		hotPinpai
	} from "../../api/index.js";
	import {
		ppSearch,
		hySearch
	} from "../../api/search.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "search",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				conDate: true, //控制404页面显示
				// imgUrl:'http://zgall.com/prod-api',
				searchList: [], //搜索列表
				keywords: '', //搜索关键词
				type: '行业', //搜索类型
				hangyeList: [], //热门行业
				pinpaiList: [], //热门品牌
				newList: [], //上新
				pageTitle:'品牌之家',
			};
		},

		created() {
			const keywords = this.$route.query.keywords;
			const type = this.$route.query.type;
			this.keywords = keywords
			this.type = type
			console.log(type, keywords, '--搜索关键词'); //输出：搜索关键词

			this.initPage();
		},
		beforeUpdate() {
			const keywords = this.$route.query.keywords;
			const type = this.$route.query.type;
			this.keywords = keywords
			this.type = type
			console.log(type, keywords, '点击搜索'); //输出：搜索关键词
		},
		computed: {},

		mounted() {
			document.title = this.pageTitle;
		},

		methods: {
			initPage() {
				this.getSerach()
				this.getHotPaihang()
				this.getHotPinpai()
			},

			//返回首页
			backHome() {
				this.$router.push({
					path: '/index',
				});
			},

			listBtn(item){
				var type = this.type
				if (type == '行业') {
					var id = Number(item.industryId)+9999
					this.$router.push({
						name: 'ranking',
						query: {industryId: id},
					});
				} else{
					var id = Number(item.brandId)+9999
					this.$router.push({
						path: '/brand/detail',
						query: {
							brandId: id,
						},
					});
				}
				
			},
			
			hangyeBtn(item){
				
				var id = Number(item.id)+9999
				this.$router.push({
					name: 'ranking',
					query: {industryId: id},
				});
			},
			
			pinpaiBtn(item){
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
			},
			
			
			//点击搜索
			searchBtn() {
				var keywords = this.$refs.headerBar.keywords
				var type = this.$refs.headerBar.value
				console.log(keywords, type, "ddddd")
				this.keywords = keywords
				this.type = type
				if (type == '行业') {
					hySearch({
						"industryName": keywords,
					}).then(res => {
						console.log('搜索行业', res.data.data)
						if (res.data.code == 200) {
							this.searchList = res.data.data.slice(0, 3)
							this.newList = res.data.data.slice(0, 8)
						}
					}).catch(error => {
						console.error('发生错误:', error);
						// this.$message({
						//     type: 'info',
						//     message: catch
						// });          
					});
				} else {
					ppSearch({
						"brandName": keywords,
					}).then(res => {
						console.log('搜索品牌', res.data.data)
						if (res.data.code == 200) {
							this.searchList = res.data.data.slice(0, 3)
							this.newList = res.data.data.slice(0, 8)
						}
					}).catch(error => {
						console.error('发生错误:', error);
						// this.$message({
						//     type: 'info',
						//     message: catch
						// });          
					});
				}


			},


			//搜索初始化
			getSerach() {
				var keywords = this.keywords
				var type = this.type
				console.log(keywords, type, "sssss")

				if (type == '行业') {
					hySearch({
						"industryName": keywords,
					}).then(res => {
						console.log('搜索行业', res.data.data)
						if (res.data.code == 200) {
							this.searchList = res.data.data.slice(0, 3);
							this.newList = res.data.data.slice(0, 8)
						}
					}).catch(error => {
						console.error('发生错误:', error);
						// this.$message({
						//     type: 'info',
						//     message: catch
						// });          
					});
				} else {
					ppSearch({
						"brandName": keywords,
					}).then(res => {
						console.log('搜索品牌', res.data.data)
						if (res.data.code == 200) {
							this.searchList = res.data.data.slice(0, 3)
							this.newList = res.data.data.slice(0, 8)
						}
					}).catch(error => {
						console.error('发生错误:', error);
						// this.$message({
						//     type: 'info',
						//     message: catch
						// });          
					});
				}

			},

			//热门行业
			getHotPaihang() {
				hotPaihang().then(res => {
					console.log(res.data.data, '热门行业');
					this.hangyeList = res.data.data.slice(0, 12);
				})
			},

			//热门品牌
			getHotPinpai() {
				hotPinpai({
					type: 2,
				}).then(res => {
					console.log(res.data.data, '热门品牌');
					this.pinpaiList = res.data.data.slice(0, 10);
				})
			},
		},
	}
</script>

<style scoped>
	@import "../../assets/css/search.css";
</style>